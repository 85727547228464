import React, { useEffect } from "react";
import { Root } from "./style";

import { useState } from "@hookstate/core";
import partition from "lodash/partition";

import PageHeader from "components/Common/PageHeader";
import SiteData from "components/Common/SiteData";
import Projects from "./Projects";

import vars from "variables";

import { useWeb3 } from "web3";

const Fundraising = ({ pageContext: { fundingProjects } }) => {
  const { bsc } = useWeb3();

  const state = useState({
    completed: [],
    raised: {},
    running: [],
  });

  useEffect(() => {
    async function fetchData() {
      const addresses = fundingProjects
        .map((project) => project.walletAddress)
        .join(",");

      fetch(`${vars.apiHost}/api/donated?to=${addresses}`)
        .then((response) => response.json())
        .then((json) => {
          const raised = {};

          fundingProjects.forEach((project) => {
            if (project.id === 15) {
              project.target = 30;
            }

            const amounts = json[project.walletAddress.toLowerCase()];
            let bnb = parseFloat(amounts.BNB || "0");
            bnb +=
              (parseFloat(amounts.HODL || "0") * bsc.usdPrices.hodl) /
              bsc.bnbUsdPrice;
            raised[project.walletAddress] = bnb;
          });

          const [running, completed] = partition(
            fundingProjects,
            (project) => raised[project.walletAddress] < project.target
          );

          state.raised.set(raised);
          state.running.set(running);
          state.completed.set(completed);
        });
    }

    fetchData();
  }, []);

  return (
    <Root className="body-width">
      <PageHeader page="fundraising" />
      <SiteData className="info" path="fundraising.info" />
      <Projects projects={state.running.get()} raised={state.raised.get()} />
      {state.completed.get().length ? (
        <div className="hr">
          <span>Completed</span>
        </div>
      ) : (
        ""
      )}
      <Projects
        projects={state.completed.get()}
        raised={state.raised.get()}
        completed={true}
      />
    </Root>
  );
};

export default Fundraising;
