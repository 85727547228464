import styled from 'styled-components';

import vars from 'variables';

import hr from 'assets/Fundraising/hr.svg';
import info from 'assets/Fundraising/info.svg';

const Root = styled.div`
  & {
    padding-bottom: 65px;
  }

  .info {
    padding: 17px 17px 17px 60px;
    background: url(${info}) rgb(0, 10, 35) no-repeat 18px 18px;
    border: 2px solid rgb(0, 149, 213);
    border-radius: 6px;
  }

  .info h3 {
    font-size: 0.85rem;
  }

  .hr {
    margin: 25px 0 45px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    background-image: url(${hr}),
                      url(${hr});
    background-repeat: no-repeat,
                       no-repeat;
  }

  ${vars.desktop.mediaQuery} {
    .info {
      margin: 105px 0 60px;
    }

    .info p {
      color: ${vars.colors.grey};
      line-height: 150%;
    }

    .hr {
      font-size: 0.8rem;
      opacity: 0.6;
      background-position: left -40px center,
                           right -40px center;
    }
  }

  ${vars.mobile.mediaQuery} {
    .info {
      margin: 55px 0 25px;
      padding-left: 45px;
      background-position: 13px 17px;
      background-size: 20px 20px;
    }

    .hr {
      font-size: 0.65rem;
      background-position: left -505px center,
                           right -505px center;
    }

    .hr span {
      opacity: 0.5;
    }
  }
`;

export { Root };
