import styled from 'styled-components';

import vars from 'variables';

import check from 'assets/Fundraising/Projects/check.svg';
import copy from 'assets/Fundraising/Projects/copy.svg';
import pointer from 'assets/Fundraising/Projects/pointer.svg';

const Root = styled.div`
  .project {
    margin-bottom: 40px;
    background: ${vars.colors.marineBlue};
    border-radius: 10px;
  }

  &.completed .project {
    opacity: 0.45;
  }

  &.completed .status {
    margin-top: 20px;
  }

  &.completed .status .bar .raised {
    background-color: #2DE508;
  }

  &.completed .pointer {
    height: 30px;
  }

  &.completed p,
  &.completed a.button {
    display: none;
  }

  h3 {
    font-size: 1.65rem;
    font-weight: 600;
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .status {
    display: flex;
  }

  .status .bar {
    background: ${vars.colors.background};
    border-radius: 5px;
  }

  .status .bar .raised {
    height: 10px;
    background: ${vars.colors.blue};
    border-radius: 5px;
  }

  .status strong,
  .pointer strong {
    text-transform: uppercase;
    margin-left: auto;
  }

  .pointer {
    position: relative;
  }

  .pointer strong {
    width: 100px;
    margin-left: -50px;
    padding-top: 12px;
    display: block;
    position: absolute;
    text-align: center;
    background: url(${pointer}) top center no-repeat;
  }

  ${vars.desktop.mediaQuery} {
    .project {
      width: calc(50% - 93px);
      padding: 42px;
      display: inline-block;
    }

    .project.even {
      margin-right: 18px;
    }

    a.button {
      padding-left: 30px;
      padding-right: 30px;
    }

    p {
      height: 45px;
      margin: 12px 0 36px;
      color: ${vars.colors.grey};
      line-height: 165%;
      -webkit-line-clamp: 2;
    }

    .status {
      padding-top: 10px;
      line-height: 10px;
    }

    .status .bar {
      flex: 0 0 calc(100% - 110px);
    }

    .status strong,
    .pointer strong {
      font-size: 0.7rem;
    }

    .pointer {
      margin-right: 110px;
      padding-top: 10px;
      height: 70px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .project {
      padding: 24px 20px;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      height: 65px;
      margin: 15px 0 20px;
      -webkit-line-clamp: 3;
    }

    a.button {
      padding-left: 37px;
      padding-right: 37px;
    }

    .status {
      line-height: 8px;
    }

    .status .bar {
      margin-top: -1px;
      flex: 0 0 calc(100% - 92px);
    }

    .status strong,
    .pointer strong {
      font-size: 0.6rem;
    }

    .pointer {
      height: 50px;
      margin-right: 92px;
      padding-top: 7px;
    }

    .pointer strong {
      background-size: 9px 9px;
    }
  }
`;

const Message = styled.div`
  text-align: center;

  p {
    margin: 0 auto;
    margin-top: 25px;
    color: #AAA;
  }

  strong {
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    display: block;
    text-transform: uppercase;
  }

  .address {
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 0.5px;
    background: url(${copy}) rgb(13, 15, 35) no-repeat center right 13px;
  }

  .address.copied {
    background-image: url(${check});
  }

  ${vars.desktop.mediaQuery} {
    & {
      width: 650px;
    }

    p {
      width: 500px;
      line-height: 150%;
    }

    strong {
      font-size: 0.8rem;
    }

    .address {
      margin: 15px 0 40px;
      padding: 15px 41px 15px 18px;
      font-size: 0.8rem;
      border-radius: 10px;
    }
  }

  ${vars.mobile.mediaQuery} {
    h4 {
      margin-top: -4px;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 1.85rem;
    }

    .address {
      padding: 11px 30px 11px 12px;
      font-size: 0.6rem;
      background-position: center right 9px;
      background-size: 14px 14px;
      border-radius: 7px;
    }

    a.button {
      margin-top: 17px;
      margin-bottom: 25px;
    }
  }
`;

export { Root, Message };
