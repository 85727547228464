import React, { useRef } from 'react';
import { Root, Message } from './style';

import { useState } from '@hookstate/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from 'components/Common/Modal';
import SiteData from 'components/Common/SiteData';

import vars from 'variables';

const Projects = ({ projects, raised, completed }) => {
  raised || (raised = {});

  const modal = useRef();
  const copied = useState(false);
  const fundingProject = useState({});

  const investIn = (project) => {
    fundingProject.set(project);
    modal.current.show();
  };

  const onCopy = () => {
    copied.set(true);
    setTimeout(() => {
      copied.set(false);
    }, 1250);
  };

  return (
    <Root className={completed ? 'completed' : ''}>
      {projects.map((project, index) => {
        const bnb = raised[project.walletAddress];
        return <div key={project.id} className={`project ${(index % 2 === 0) ? 'even' : ''}`}>
          <h3>{project.title}</h3>
          <div className={completed ? 'completed' : ''}>
            <p>{project.description}</p>
            <div className="status">
              <div className="bar">
                <div
                  className="raised"
                  style={
                    {width: `${Math.min(bnb / project.target * 100, 100)}%`}
                  }/>
              </div>
              <strong>
                {completed ? 'Target reached' : `${project.target} BNB target`}
              </strong>
            </div>
            <div className="pointer">
              <strong
                style={
                  {left: `${Math.min(bnb / project.target * 100, 100)}%`}
                }>
                {bnb ? bnb.toFixed(0) : bnb} BNB
              </strong>
            </div>
            <a className="button blue" onClick={() => investIn({...project})}>Invest now</a>
          </div>
        </div>
      })}
      <Modal usedRef={modal}>
        <Message>
          <h4>
            <span>{fundingProject.get().title}</span>
          </h4>
          <SiteData path="fundraising.modalMessage"/>
          <CopyToClipboard
            text={fundingProject.get().walletAddress}
            onCopy={onCopy}>
            <div className={`address ${copied.get() ? 'copied' : ''}`}>
              {fundingProject.get().walletAddress}
            </div>
          </CopyToClipboard>
          <br/>
          <a className="button blue telegram" href={vars.links.telegram} target="_blank" rel="noreferrer">
            Telegram
          </a>
        </Message>
      </Modal>
    </Root>
  );
};

export default Projects;
